import React from 'react';
import { graphql, Link } from 'gatsby';
import { startCase, capitalize } from 'lodash';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { GatsbyImage } from 'gatsby-plugin-image';
import { toWords, toWordsOrdinal } from 'number-to-words';

import Layout from '../components/layout';
import Head from '../components/head';

import MapIcon from '../assets/map.png';

import styles from './vehicle.module.scss';

const HollingdeanStreets = [
  'Adams Close',
  'Hollingbury Crescent',
  'Stanmer Park Road',
  'Stanmer Villas',
  'Roedale Road',
  'Hollingdean Terrace',
  'Hollingbury Place',
  'Hollingdean Street',
  'Dudley Road',
  'Stanmer Street',
  'The Crossway',
  'Upper Hollingdean Road',
  'Horton Road',
  'The Crestway',
  'The Linkway',
];

const ZoneFStreets = ['Hollingbury Park Avenue'];

const ZoneGStreets = ['Hollingbury Road'];

const ZoneJStreets = ['Preston Drove', 'Southdown Road'];

const monthMap = {
  January: 'Jan',
  February: 'Feb',
  March: 'Mar',
  April: 'Apr',
  May: 'May',
  June: 'Jun',
  July: 'Jul',
  August: 'Aug',
  September: 'Sept',
  October: 'Oct',
  November: 'Nov',
  December: 'Dec',
};

const monthPattern = new RegExp(Object.keys(monthMap).join('|'), 'g');

const yearMap = {
  2020: `'20`,
  2021: `'21`,
  2022: `'22`,
  2023: `'23`,
  2024: `'24`,
  2025: `'25`,
};

const yearPattern = new RegExp(Object.keys(yearMap).join('|'), 'g');

const options = {
  renderNode: {
    'embedded-asset-block': (node) => {
      const src = node.data.target.fields.file['en-US'].url;
      const alt = node.data.target.fields.title['en-US'];
      return <img src={src} alt={alt} />;
    },
    hyperlink: (node, children) => {
      const URI = node.data.uri;
      const isInternal = URI.includes('hollingdeanparking');

      if (isInternal) {
        const to = URI.replace('http://', '')
          .replace('https://', '')
          .replace('www.', '')
          .replace('hollingdeanparking.co.uk', '')
          .replace('hollingdeanparking.com', '')
          .replace('gallery/', '');

        return <Link to={`/gallery${to.toLowerCase()}`}>{children}</Link>;
      }

      return (
        <a href={URI} target="_blank" rel="noreferrer noopener nofollow">
          {children}
        </a>
      );
    },
  },
};

const formatStreets = (streets) => {
  if (!streets.length) {
    return '';
  }

  if (streets.length === 1) {
    return streets[0];
  }

  let allButLast = streets.slice(0, -1).join(', ');
  return `${allButLast} and ${streets[streets.length - 1]}`;
};

const VehiclePage = ({
  data: {
    thisVehicle: {
      numberPlate,
      make,
      model,
      colour,
      streets,
      vehicleType,
      notResident,
      isAbandoned,
      isCommuter,
      isForSale,
      description,
      images,
      shareImages,
    },
  },
  pageContext: { next, prev },
}) => {
  const URLise = (string) => string.trim().toLowerCase().replace(' ', '-');

  const shareImageNumber = notResident && shareImages.length > 1 ? 1 : 0;

  const shareImage = `https://hollingdeanparking.com${shareImages[shareImageNumber].localFile.childImageSharp.resize.src}`;

  const pageDescription = `Photographs of ${numberPlate} (a ${colour.toLowerCase()} ${make} ${model}${
    vehicleType === 'Camper' ? ' camper van' : ''
  }${vehicleType === 'Taxi' ? ' taxi' : ''}) parked on ${formatStreets(
    streets
  )} in Hollingdean, Brighton. Photos collected as part of a campaign to identify non-resident, displaced, and abandoned vehicles using Hollingdean streets.`;

  return (
    <Layout>
      <Head
        title={`${numberPlate} - ${startCase(colour)} ${
          make === 'BMW' ? make : startCase(make)
        } ${model}`}
        description={pageDescription}
        {...{ shareImage }}
      />
      <main className={styles.vehicle}>
        <section className={styles.info}>
          <h1 className={styles.title}>{numberPlate}</h1>
          <h2 className={styles.subtitle}>
            <Link to={`/gallery/${URLise(colour)}/`}>{startCase(colour)}</Link>{' '}
            <Link to={`/gallery/${URLise(make)}/`}>
              {make === 'BMW' ? make : startCase(make)}
            </Link>{' '}
            <Link to={`/gallery/${URLise(make)}/${URLise(model)}/`}>
              {model}
            </Link>
          </h2>
          {documentToReactComponents(description.json, options)}
        </section>

        <section className={styles.images}>
          {images.map(
            (
              {
                localFile: {
                  childImageSharp: { gatsbyImageData },
                },
                location,
              },
              i
            ) => (
              <figure className={styles.mediaWrap} key={`image-${i}`}>
                <GatsbyImage
                  image={gatsbyImageData}
                  className={styles.image}
                  alt={`Photograph of ${numberPlate} - a ${colour} ${make} ${model}${
                    vehicleType === 'Camper' ? ' camper van' : ''
                  }${
                    vehicleType === 'Taxi' ? ' taxi' : ''
                  } parked in Hollingdean${
                    notResident && !isCommuter ? ' by a non-resident' : ''
                  }${isAbandoned ? ', and potentially abandoned' : ''}${
                    isCommuter
                      ? ' by a non-resident who uses the local area as part of their Brighton commute'
                      : ''
                  }${
                    isForSale
                      ? ' and stored here whilst a dodgy car dealer attempts to sell it'
                      : ''
                  }. ${
                    images.length > 1
                      ? `The ${toWordsOrdinal(i + 1)} of ${toWords(
                          images.length
                        )} photographs supplied by the residents of Hollingdean.`
                      : ''
                  }`}
                  placholder="dominantColor"
                  loading={i !== 0 ? 'lazy' : ''}
                />
                {location.length > 1 && (
                  <figcaption
                    className={styles.imageLocation}
                    title={`Photograph of ${numberPlate} located on ${location}`}
                  >
                    {!location.startsWith('...') && (
                      <img src={MapIcon} alt="Map icon" />
                    )}
                    {location
                      .replace(monthPattern, (match) => monthMap[match])
                      .replace(yearPattern, (match) => yearMap[match])}{' '}
                    {HollingdeanStreets.some((street) =>
                      location.includes(street)
                    )
                      ? '(Hollingdean)'
                      : ''}
                    {ZoneGStreets.some((street) => location.includes(street))
                      ? '(Zone G - Fiveways)'
                      : ''}
                    {ZoneJStreets.some((street) => location.includes(street))
                      ? '(Zone J - Fiveways)'
                      : ''}
                    {ZoneFStreets.some((street) => location.includes(street))
                      ? '(Zone F - Fiveways)'
                      : ''}
                  </figcaption>
                )}
              </figure>
            )
          )}
          {numberPlate === 'DS69 MGU' && (
            <figure className={styles.mediaWrap}>
              <video
                autoPlay
                muted
                loop
                disablePictureInPicture
                playsInline
                poster="https://hollingdeanparking.com/static/DS69-MGU.jpg"
                className={styles.video}
              >
                <source
                  src="https://hollingdeanparking.com/static/DS69-MGU.mp4"
                  type="video/mp4"
                />
                <source
                  src="https://hollingdeanparking.com/static/DS69-MGU.webm"
                  type="video/webm"
                />
              </video>
            </figure>
          )}
        </section>
        <nav className={styles.navigation}>
          <Link to={`/gallery/${prev.replace(' ', '-').toLowerCase()}/`}>
            Previous
          </Link>{' '}
          /{' '}
          <Link to={`/gallery/${next.replace(' ', '-').toLowerCase()}/`}>
            Next
          </Link>
        </nav>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query vehiclePage($id: String!) {
    thisVehicle: contentfulVehicle(id: { eq: $id }) {
      numberPlate
      make
      model
      colour
      streets
      vehicleType
      notResident
      isAbandoned
      isCommuter
      isForSale
      description {
        json
      }
      images {
        location: description
        localFile {
          childImageSharp {
            gatsbyImageData(
              quality: 40
              width: 1000
              placeholder: DOMINANT_COLOR
            )
          }
        }
      }
      shareImages: images {
        localFile {
          childImageSharp {
            resize(
              fit: COVER
              toFormat: JPG
              width: 1200
              height: 630
              quality: 40
            ) {
              src
            }
          }
        }
      }
    }
  }
`;

export default VehiclePage;
